<template>
  <div v-loading="loading">
    <div class="card card-custom card-stretch gutter-b response-answer">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">回答検索</b></h3>
        </div>
        <div>
          <ButtonSearchMobile></ButtonSearchMobile>
        </div>
      </div>
      <div class="card-body">
        <div id="search-form">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>フォーム名</label>
                      <input name="form_name" placeholder="フォーム名を入力" v-model="answer.form_name" type="text" class="form-control"/>
                    </div>
                  </div>
                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>項目名フリーワード</label>
                      <input name="label_name" placeholder="項目名フリーワードを入力" v-model="answer.label_name" type="text" class="form-control" />
                    </div>
                  </div>

                  <div class="col-md-4 col-6">
                    <div class="form-group">
                      <label>回答内容フリーワード</label>
                      <input name="answer_value" placeholder="回答内容フリーワードを入力" v-model="answer.answer_value" type="text" class="form-control"/>
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="button-submit-mobile">
                      <button type="submit" @click="showFormSearch()" class="btn btn-primary mr-2">{{$t('search')}}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group action-form-search">
                  <button type="submit" class="btn btn-primary mr-2">{{$t('search')}}</button>
                  <button @click="exportExcel" :disabled="formAnswerList.total <=0" type="button" class="btn btn-primary mr-2">{{$t('export_zip')}}</button>
                </div>
              </div>
            </div>
              <div class="row">
                  <div class="col-md-9">
                      <div class="row">
                          <div class="col-md-4 col-6">
                              <div class="form-group">
                                  <label>回答者Key</label>
                                  <input name="unique_key_value" placeholder="回答者Keyを入力" v-model="answer.unique_key_value" type="text" class="form-control" />
                              </div>
                          </div>
                          <div class="col-md-4 col-6">
                              <div class="form-group">
                                  <label>回答ID</label>
                                  <input name="answer_id" placeholder="回答IDを入力" v-model="answer.answer_id" type="text" class="form-control" />
                              </div>
                          </div>
                          <div class="col-md-4 col-6">
                              <div class="form-group">
                                  <label class="d-block">ステータス</label>
                                  <el-select class="w-100 bg-gray-item"
                                             v-model="answer.answer_status"
                                             multiple
                                             placeholder="選択してください">
                                      <el-option
                                          v-for="(item, index) in constants.STATUS_ANSWER_SEARCH"
                                          :key="item.STATUS"
                                          :label="item.KEY"
                                          :value="parseInt(item.STATUS)">
                                      </el-option>
                                  </el-select>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-9">
                      <div class="row">
                          <div class="col-md-4 col-6">
                              <div class="form-check form-check-inline">
                                  <el-checkbox v-model="answer.delete_flag" label="ゴミ箱データのみを表示する" ></el-checkbox>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
        </div>
        <div id="data-table-list">
          <div class="header-table-mobile">
            <button @click="exportExcel" :disabled="formAnswerList.total <= 0" type="button" class="btn btn-primary mr-2">{{$t('export_zip')}}</button>
          </div>

          <div class="row float-right pr-5">
            <my-pagination
                v-if="formAnswerList.total"
                :total-record="parseInt(formAnswerList.total)"
                :current-page="parseInt(formAnswerList.current_page)"
                :page-size-default="parseInt(formAnswerList.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
          <el-table
              v-if="formAnswerList.data && formAnswerList.total > 0"
              class="mb-5 responsive-answer-table"
              :data="formAnswerList.data"
              style="width: 100%"
              @sort-change="sortChange"
          >
            <el-table-column
                prop="answer_id"
                label="回答ID"
                sortable="false"
                class="table-column-custom"
                min-width="110"
            >
            <template slot-scope="scope">
                <span>
                 </span>
                <span v-if="hasPermissonCheck('answer_flg')">
                   <router-link :to="{ name: 'form-base-detail', params: { answer_id:  scope.row.answer_id,form_id:scope.row.form_id }}">{{ scope.row.answer_id }}</router-link>
                </span>
                <span v-else>
                  <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.answer_id }}</a>
                </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="form_name"
                label="フォーム名"
                sortable="false"
                class="table-column-custom"
                min-width="130"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.form_base === null" class="statusDTD"></span>
                <span v-else-if="scope.row.form_base !== null && scope.row.form_base.delete_flag === form_base_delete_flag.ACTIVE" class="statusDKK">
                   <span v-if="hasPermissonCheck('answer_flg')">
                     <router-link :to="{ name: 'edit-form', params: { form_id:scope.row.form_id }}">{{ scope.row.form_base.form_name }}</router-link>
                   </span>
                   <span v-else>
                    <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.form_base.form_name }}</a>
                   </span>
                 </span>
                  <span v-else-if="scope.row.form_base !== null && scope.row.form_base.delete_flag ===  form_base_delete_flag.DELETE">
                      {{ scope.row.form_base.form_name }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="unique_key_value"
                label="回答者Key"
                sortable="false"
                class="table-column-custom"
                min-width="210"
            >
            </el-table-column>
<!--              phase 2-->
            <el-table-column
                sortable="false"
                prop="approval_step"
                label="承認STEP"
                class="table-column-custom"
                min-width="210"
            >
            <template slot-scope="scope">
                <a href=""
                   @click.prevent="approvalProcess(scope.row)"
                   v-if="scope.row.form_base && scope.row.form_base.approval">
                    {{ (scope.row.total_approval ? scope.row.total_approval : 0) +'/' +'ステップ '+ scope.row.current_step}}
                </a>
              </template>
            </el-table-column>
<!--              phase 2-->
            <el-table-column
                prop="answer_first_time"
                label="入力完了日（初回）"
                sortable="false"
                class="table-column-custom"
                min-width="210"
            >
          </el-table-column>
            <el-table-column
                prop="answer_datetime"
                label="入力完了日（更新）"
                sortable="false"
                class="table-column-custom"
                min-width="210"
            >
            </el-table-column>
            <el-table-column
                prop="answer_status"
                label="ステータス"
                sortable="false"
                class="table-column-custom"
                min-width="210"
            >
              <template slot-scope="scope"
              >
                <span v-if="parseInt(scope.row.condition_status) === constants.STATUS_CONDITION.DRAFT.KEY" class="statusDTD">{{constants.STATUS_CONDITION.DRAFT.VALUE}}</span>
                <span v-else-if="parseInt(scope.row.answer_status) === constants.STATUS_ANSWER.APPROVAL.KEY" class="statusDTD">{{constants.STATUS_ANSWER.APPROVAL.VALUE}}</span>
                <span v-else-if="parseInt(scope.row.answer_status) === constants.STATUS_ANSWER.DENIAL.KEY" class="statusDKK">{{constants.STATUS_ANSWER.DENIAL.VALUE}}</span>
                <span v-else-if="parseInt(scope.row.answer_status) === constants.STATUS_ANSWER.HOLD.KEY" class="statusDKK">{{constants.STATUS_ANSWER.HOLD.VALUE}}</span>
                <span v-else-if="parseInt(scope.row.answer_status) === constants.STATUS_ANSWER.REVIEW.KEY" class="statusOD">{{constants.STATUS_ANSWER.REVIEW.VALUE}}</span>
              </template>
            </el-table-column>

            <el-table-column
                sortable="false"
                prop="tenant_root"
                label="テナント名"
                min-width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.tenant_root_value">
                  <a target="_blank" :href="scope.row.tenant_root_value.admin_url">
                    {{scope.row.tenant_root_value.tenant_name}}
                  </a>
                </span>
              </template>
            </el-table-column>

          </el-table>
          <div v-else class="text-center no-list-data">{{$t('el.table.emptyText')}}</div>

          <div class="row float-right pr-5">
            <my-pagination
                v-if="formAnswerList.total"
                :total-record="parseInt(formAnswerList.total)"
                :current-page="parseInt(formAnswerList.current_page)"
                :page-size-default="parseInt(formAnswerList.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
        </div>

      </div>
    </div>
    <!-- modal approval process -->
    <b-modal id="process-approval" class="approval" hide-heade hide-footer centered>
      <div>
        <div class="modal-header text-center">
          <h3 style="width: 100%">承認プロセスログ</h3>
        </div>
        <div class="modal-body">
          <el-row>
            <el-col :span="12" class="text-center">
              <el-row >
                <el-col :span="12">承認プロセス段階</el-col>
                <el-col :span="6">{{answer.approval_step+ '/' +'ステップ '+ answer.t_approval_count}}</el-col>
              </el-row>
            </el-col>
            <el-col :span="10" class="text-center">
              <el-row>
                <el-col :span="20">現担当権限</el-col>
                <el-col :span="4">{{answer.approval_role}}</el-col>
              </el-row>
            </el-col>
          </el-row>
          <br>
          <!-- table -->
          <el-row>
             <el-table
             :data="processApproval"
              class="mb-5 table-search text-center"
              style="width: 100%">
                    <el-table-column
                        prop="step"
                        class="column-table"
                        label="ステップ">
                        <template slot-scope="scope">
                            <span class="statusDTD">{{'ステップ '+scope.row.step}}</span>

                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="approval_datetime"
                      class="column-table"
                      label="日付">
                  </el-table-column>
                  <el-table-column
                      prop="approval_status"
                      class="column-table"
                      label="アクション">
                        <template slot-scope="scope">
                            <span v-if="parseInt(scope.row.approval_status) === constants.T_APPROVAL_STATUS.approval.key" class="statusDTD">{{constants.T_APPROVAL_STATUS.approval.value}}</span>
                            <span v-else-if="parseInt(scope.row.approval_status) === constants.T_APPROVAL_STATUS.denial.key" class="statusDKK">{{constants.T_APPROVAL_STATUS.denial.value}}</span>
                            <span v-else-if="parseInt(scope.row.approval_status) === constants.T_APPROVAL_STATUS.hold.key" class="statusDKK">{{constants.T_APPROVAL_STATUS.hold.value}}</span>
                            <span v-else-if="parseInt(scope.row.approval_status) === constants.T_APPROVAL_STATUS.change_in_charge.key" class="statusOD">{{constants.T_APPROVAL_STATUS.change_in_charge.value}}</span>
                        </template>
                  </el-table-column>
                  <el-table-column
                      prop="responsible_role"
                      class="column-table"
                      label="担当ロール">
                  </el-table-column>
                  <el-table-column
                      prop="process_user_id"
                      class="column-table"
                      label="処理者">
                  </el-table-column>
                  <el-table-column
                      prop="wariate_user_id"
                      class="column-table"
                      label="割当先">
                  </el-table-column>
                  <el-table-column
                      prop="comment"
                      class="column-table"
                      label="コメント">
                    <template slot-scope="scope">
                      <pre class="pre-comment">{{ scope.row.comment }}</pre>
                    </template>
                  </el-table-column>

          </el-table>
          </el-row>
        </div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button class="btn-primary" @click="closePopup()">
            {{ $t("close_popup_error") }}
          </b-button>
        </div>
      </div>
    </b-modal>
      <b-modal id="confirm-zipfile" hide-header hide-footer centered>
          <div>
              <div class="modal-body">Zipファイルはメールアドレスに送信しますので、少しお待ちください。</div>
              <div class="modal-footer text-center" style="justify-content: center">
                  <b-button class="btn-primary" @click="$bvModal.hide('confirm-zipfile')">
                      はい
                  </b-button>
              </div>
          </div>
      </b-modal>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>

<script>
import mixinSearch from "@/mixins/searchMixin";
import * as constants from "@/core/config/constant";
import {mapActions, mapGetters, mapMutations} from "vuex";

import {
  GET_LIST_FORM_ANSWER,
  EXPORT_FORM_ANSWER_FILE_CSV,
} from "@/core/services/store/formAnswer.module";
import ButtonSearchMobile from "@/view/components/ButtonSearchMobile";

export default {
  name: "list",
  components: {ButtonSearchMobile},
  mixins: [mixinSearch],
  data() {
    return {
      loading:false,
      answer:{
        form_name:'',
        label_name:'',
        answer_value:'',
        t_approval_count: '',
        approval_step: '',
        approval_role: '',
        delete_flag : false,
        unique_key_value:'',
        answer_id: '',
        answer_status:[]

      },
      processApproval: [],
      constants: constants,
      form_base_delete_flag: constants.FORM_BASE_DELETE_FLAG,
      not_permisson_popup:false
    }
  },
  computed: {
    ...mapGetters([
      'formAnswerList'
    ]),
    queryParam() {
      return this.$route.query
    },
  },
  watch: {
      'answer.delete_flag'(newValue) {
         if (newValue == 'true') {
             this.answer.delete_flag = true
         }
          if (newValue == 'false') {
              this.answer.delete_flag = false
          }
      },
    async queryParam() {
      this.loading = true;
        if (Object.keys(this.queryParam).length === 0){
            this.resetDataSearch();
        }
      await this.getListFormAnswer(this.queryParam);
      this.getDataFromQuery();
      this.loading = false;
    }
  },
  async created() {
    this.loading = true;
      if (!this.hasPermissonCheck('answer_flg')){
          this.not_permisson_popup = true
      }else {
          this.getDataFromQuery();
          await this.getListFormAnswer(this.queryParam);
      }

    this.loading = false;

  },
  methods: {
    ...mapActions([GET_LIST_FORM_ANSWER, EXPORT_FORM_ANSWER_FILE_CSV]),
    sortChange(sortProps){
      this.sortColumn('form-answer-list',sortProps);
    },
    getDataFromQuery(){
      this.answer.form_name = this.queryParam.form_name ? this.queryParam.form_name : '';
      this.answer.label_name = this.queryParam.label_name ? this.queryParam.label_name : '';
      this.answer.answer_value = this.queryParam.answer_value ? this.queryParam.answer_value : '';
      this.answer.delete_flag = this.queryParam.delete_flag ? this.queryParam.delete_flag : false;
      this.answer.unique_key_value = this.queryParam.unique_key_value ? this.queryParam.unique_key_value : '';
      this.answer.answer_id = this.queryParam.answer_id ? this.queryParam.answer_id : '';
      let answerStatus = (this.queryParam.answer_status && this.queryParam.answer_status.length ) ? this.queryParam.answer_status.split(',') : [];
      this.answer.answer_status = answerStatus.map(item => parseInt(item));
    },
    onSubmit(){
      this.answer.form_name = this.answer.form_name.trim()
      this.answer.label_name = this.answer.label_name.trim()
      this.answer.answer_value = this.answer.answer_value.trim()
      this.answer.unique_key_value = this.answer.unique_key_value.trim()
      this.answer.answer_id = this.answer.answer_id.trim()
      this.getDataSearch(this.answer);
      this.search("form-answer-list")
    },
    handleSizeChange(val) {
      this.sizeChange('form-answer-list',val);
    },
    handleCurrentChange(val) {
      this.pageChange('form-answer-list',val);
    },
    async exportExcel(){
        if (this.hasPermissonCheck('answer_flg')) {
            this.loading = true;
            await this.exportFormAnswerFileCsv(this.queryParam);
            this.loading = false;
            this.$bvModal.show('confirm-zipfile');
        } else {
            this.not_permisson_popup = true
        }

    },
    approvalProcess(data){
      this.answer.t_approval_count = data.current_step;
      this.answer.approval_step = data.total_approval ? data.total_approval : 0
      this.answer.approval_role = data.approval_role
      this.processApproval = data.t_approval
      this.$bvModal.show('process-approval');
    },
    closePopup(){
      this.$bvModal.hide('process-approval');
    }
  },

}
</script>
<style scoped lang="scss">
#inlineCheckbox1 {
    width: 23px;
    height: 18px;
}
</style>
